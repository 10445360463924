import { createAsyncThunk } from '@reduxjs/toolkit';
import {
  fetchVideosRequest,
  fetchVideoRequest,
  fetchPlatformRequest,
  fetchPlatformsRequest,
  fetchFilmsRequest,
  updateFilmEventRequest,
  uploadRequest,
  storeFilmRequest,
  fetchFilmRequest,
} from './apis';

// const convertVideosIntoSeparateForm = (data) => {
//   const newVideos = [];

//   data.forEach((item) => {
//     if (item.watch_online && item.watch_in_person) {
//       const {
//         watch_online,
//         watch_in_person,
//         end_date_in_person,
//         end_date_online,
//         start_date_in_person,
//         start_date_online,
//         ...rest
//       } = item;

//       const online = {
//         ...rest,
//         watch_online,
//         end_date_online,
//         start_date_online,
//       };

//       const in_person = {
//         ...rest,
//         watch_in_person,
//         start_date_in_person,
//         end_date_in_person,
//       };

//       newVideos.push(online, in_person);
//     } else {
//       newVideos.push(item);
//     }
//   });

//   return newVideos;
// };

export const fetchAllFilms = createAsyncThunk(
  'films/fetchAllFilms',
  async (query, { rejectWithValue }) => {
    try {
      const res = await fetchFilmsRequest(query);

      return res.data.data;
    } catch (error) {
      return rejectWithValue(error.response.data.message);
    }
  }
);

export const fetchFilms = createAsyncThunk(
  'films/fetchFilms',
  async (query, { rejectWithValue }) => {
    try {
      const res = await fetchFilmsRequest(query);

      const data = res.data.data;

      // const newData = convertVideosIntoSeparateForm(data);

      return data;
    } catch (error) {
      return rejectWithValue(error.response.data.message);
    }
  }
);

export const fetchFilm = createAsyncThunk(
  'films/fetchFilm',
  async (query, { rejectWithValue }) => {
    try {
      const res = await fetchFilmRequest(query);
      return res.data.data;
    } catch (error) {
      return rejectWithValue(error.response.data.message);
    }
  }
);

export const fetchVideos = createAsyncThunk(
  'films/fetchVideos',
  async ({ chapterId }, { rejectWithValue }) => {
    try {
      const res = await fetchVideosRequest(chapterId);
      return res.data.data;
    } catch (error) {
      return rejectWithValue(error.response.data.message);
    }
  }
);

export const fetchVideo = createAsyncThunk(
  'films/fetchVideo',
  async ({ videoId }, { rejectWithValue }) => {
    try {
      const res = await fetchVideoRequest(videoId);
      return res.data.data[0];
    } catch (error) {
      return rejectWithValue(error.response.data.message);
    }
  }
);

export const fetchVideosAndPlatforms = createAsyncThunk(
  'films/fetchVideosAndPlatforms',
  async ({ chapterId }, { rejectWithValue }) => {
    try {
      const res = await fetchVideosRequest(chapterId);
      const res2 = await fetchPlatformsRequest(chapterId);

      return { videos: res.data.data, platforms: res2.data.data };
    } catch (error) {
      return rejectWithValue(error.response.data.message);
    }
  }
);

export const fetchPlatform = createAsyncThunk(
  'films/fetchPlatform',
  async ({ platformId }, { rejectWithValue }) => {
    try {
      const res = await fetchPlatformRequest(platformId);
      return res.data.data[0];
    } catch (error) {
      return rejectWithValue(error.response.data.message);
    }
  }
);

export const uploadFile = createAsyncThunk(
  'films/uploadFile',
  async (payload) => {
    const res = await uploadRequest(payload);
    return res.data;
  }
);

export const storeFilm = createAsyncThunk('films/storeFilm', async (data) => {
  const res = await storeFilmRequest(data);
  return res.data.data;
});

export const updateFilmEvent = createAsyncThunk(
  'films/updateFilmEvent',
  async (data) => {
    const res = await updateFilmEventRequest(data);
    return res.data.data;
  }
);
