import { createAsyncThunk } from '@reduxjs/toolkit';
import { getFeedbacks, postFeedback, patchFeedback } from './api';

export const fetchFeedbacks = createAsyncThunk(
  'feedback/fetchFeedbacks',
  async (query, { rejectWithValue }) => {
    try {
      const res = await getFeedbacks(query);
      return res.data.data;
    } catch (error) {
      return rejectWithValue(error.response.data.message);
    }
  }
);

export const storeFeedback = createAsyncThunk(
  'feedback/storeFeedback',
  async (data) => {
    const res = await postFeedback(data);
    return res.data.data;
  }
);

export const updateFeedback = createAsyncThunk(
  'feedback/updateFeedback',
  async (data) => {
    const res = await patchFeedback(data);
    return res.data.data;
  }
);
