import { createAsyncThunk } from '@reduxjs/toolkit';
import { allPurchase } from './apis';

export const fetchAllPurchase = createAsyncThunk(
  'purchase/fetchAllPurchase',
  async (query, { rejectWithValue }) => {
    try {
      const res = await allPurchase(query);
      return res.data.data;
    } catch (error) {
      return rejectWithValue(error.response.data.data.raw.message);
    }
  }
);
