import { createAsyncThunk } from '@reduxjs/toolkit';
import fetchSurprisesRequest from './apis';

const fetchSurprises = createAsyncThunk(
  'surprise/fetchSurprises',
  async (_, { rejectWithValue }) => {
    try {
      const res = await fetchSurprisesRequest();
      return res.data.data;
    } catch (error) {
      return rejectWithValue(error.response.data.message);
    }
  }
);

export default fetchSurprises;
