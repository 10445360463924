import { createSlice } from '@reduxjs/toolkit';
import { fetchTeam, fetchUserTeam } from './action';

export const teamSlice = createSlice({
  name: 'team',
  initialState: {
    teams: [],
    team: null,
    isLoading: true,
    error: null,
  },
  reducers: {},
  extraReducers: {
    [fetchTeam.pending]: (state) => {
      state.error = null;
    },
    [fetchTeam.fulfilled]: (state, { payload }) => {
      state.teams = payload;
      state.isLoading = false;
    },
    [fetchTeam.rejected]: (state, { payload }) => {
      state.isLoading = false;
      state.error = payload;
    },
    [fetchUserTeam.pending]: (state) => {
      state.error = null;
    },
    [fetchUserTeam.fulfilled]: (state, { payload }) => {
      state.team = payload;
      state.isLoading = false;
    },
    [fetchUserTeam.rejected]: (state, { payload }) => {
      state.isLoading = false;
      state.error = payload;
    },
  },
});

export default teamSlice.reducer;
