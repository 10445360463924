import axiosInstance from '../../../helpers/axios';

export default function fetchUsersRequest(query) {
  return axiosInstance.get(`users${query ? `?${query}` : ''}`);
}

export const fetchUserRequest = ({ query, userId }) => {
  return axiosInstance.get(`user/${userId}${query ? `?${query}` : ''}`);
};

export const fetchPaymentMethodRequest = () => {
  return axiosInstance.get(`user/payment-method`);
};

export const addPaymentMethodRequest = (payload) => {
  return axiosInstance.post(`user/payment-method`, payload);
};

export const removePaymentMethodRequest = (payload) => {
  return axiosInstance.post(`user/payment-method/remove`, payload);
};
