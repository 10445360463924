import axiosInstance from '../../../helpers/axios';

export const getFeedbacks = (query) => {
  return axiosInstance.get(`feedback/list${query}`);
};

export const postFeedback = (data) => {
  return axiosInstance.post('feedback/store', data);
};

export const patchFeedback = ({ feedbackId, data }) => {
  return axiosInstance.patch(`feedback/update/${feedbackId}`, data);
};
