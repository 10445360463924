import axiosInstance from '../../../helpers/axios';

const postNotebook = (payload) => {
  return axiosInstance.post('notepad/store', payload);
};

const patchNotebook = ({ notebookId, data }) => {
  return axiosInstance.patch(`notepad/${notebookId}`, data);
};

const getNotebooks = (userId) => {
  return axiosInstance.get(`notepad/get_by_query?user_id=${userId}`);
};

const getNotebook = (userId) => {
  return axiosInstance.get(`notepad/get_by_query?user_id=${userId}`);
};

const deleteNotebook = (id) => {
  return axiosInstance.delete(`notepad/${id}`);
};

export {
  postNotebook,
  getNotebooks,
  patchNotebook,
  getNotebook,
  deleteNotebook,
};
