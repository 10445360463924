import { createAsyncThunk } from '@reduxjs/toolkit';
import { getBoards, patchBoard, postBoard, deleteBoard } from './apis';

export const fetchBoards = createAsyncThunk(
  'board/fetchBoards',
  async (query, { rejectWithValue }) => {
    try {
      const res = await getBoards(query);
      return res.data.data;
    } catch (error) {
      return rejectWithValue(error.response.data.message);
    }
  }
);

export const createBoard = createAsyncThunk(
  'board/createBoard',
  async (data) => {
    const res = await postBoard(data);
    return res.data.data;
  }
);

export const updateBoard = createAsyncThunk(
  'board/updateBoard',
  async (data) => {
    const res = await patchBoard(data);
    return res.data.data;
  }
);

export const removeBoard = createAsyncThunk(
  'board/removeBoard',
  async (boardId) => {
    await deleteBoard(boardId);
    return boardId;
  }
);
