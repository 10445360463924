/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';
import fetchLessons from './action';

export const lessonsSlice = createSlice({
  name: 'lessons',
  initialState: {
    lessons: [],
    isLoading: true,
    error: null,
  },
  reducers: {},
  extraReducers: {
    [fetchLessons.pending]: (state) => {
      state.isLoading = true;
      state.error = null;
    },
    [fetchLessons.fulfilled]: (state, { payload }) => {
      state.lessons = payload;
      state.isLoading = false;
    },
    [fetchLessons.rejected]: (state, { payload }) => {
      state.isLoading = false;
      state.error = payload;
    },
  },
});

export const { setLessons } = lessonsSlice.actions;
export default lessonsSlice.reducer;
