import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { Center, CircularProgress } from '@chakra-ui/react';

const AuthenticatedRoute = ({ component: Component, ...rest }) => {
  const { token, currentUser } = useSelector((state) => state.auth);

  switch (!!token) {
    case true:
      switch (!!currentUser) {
        case true:
          return (
            <Route
              {...rest}
              render={(props) => {
                switch (!!token) {
                  case true:
                    return <Component {...props} />;
                  default:
                    return <Redirect to="/login" />;
                }
              }}
            />
          );
        default:
          return (
            <Center w="100vw" h="100vh">
              <CircularProgress
                isIndeterminate
                color="whiskey.500"
                thickness="4px"
              />
            </Center>
          );
      }
    default:
      return <Redirect to="/login" />;
  }
};

AuthenticatedRoute.propTypes = {
  component: PropTypes.objectOf(PropTypes.any).isRequired,
};

export default AuthenticatedRoute;
