import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import axiosInstance from 'helpers/axios';
import { logout } from 'store/reducers/auth/reducer';
import { ColorModeScript, ChakraProvider } from '@chakra-ui/react';
import App from './App';
import store from './store';
import theme from './theme';
import './i18n';

const UNAUTHORIZED = 401;
const { dispatch } = store;

axiosInstance.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    if (error.response.status === UNAUTHORIZED) {
      dispatch(logout());
    }
    return Promise.reject(error);
  }
);

ReactDOM.hydrate(
  <ChakraProvider theme={theme}>
    <ColorModeScript initialColorMode={theme.config.initialColorMode} />
    <Provider store={store}>
      <App />
    </Provider>
  </ChakraProvider>,
  document.getElementById('root')
);
