/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';
import {
  fetchVideo,
  fetchVideos,
  fetchVideosAndPlatforms,
  fetchPlatform,
  fetchFilms,
  updateFilmEvent,
  fetchFilm,
  fetchOrganizerEvents,
} from './action';

export const videoSelector = (state) => state.video;

const initialState = {
  videos: [],
  events: [],
  video: null,
  platforms: [],
  platform: null,
  films: [],
  film: null,
  isLoading: true,
  error: null,
};

export const videosSlice = createSlice({
  name: 'videos',
  initialState,
  reducers: {
    setIsLoading: (state, { payload }) => {
      state.isLoading = payload;
    },
    setFilm: (state, { payload }) => {
      state.video = { ...state.video, description: payload.description };
    },
    setPlatform: (state, { payload }) => {
      state.platform = { ...state.platform, description: payload.description };
    },
    setError: (state, { payload }) => {
      state.error = payload;
    },
    setFilmState: (state) => {
      Object.assign(state, initialState);
    },
  },
  extraReducers: {
    [fetchVideosAndPlatforms.pending]: (state) => {
      state.isLoading = true;
      state.error = null;
    },
    [fetchVideosAndPlatforms.fulfilled]: (state, { payload }) => {
      state.videos = payload.videos;
      state.platforms = payload.platforms;
      state.isLoading = false;
    },
    [fetchVideosAndPlatforms.rejected]: (state, { payload }) => {
      state.isLoading = false;
      state.error = payload;
    },
    [fetchVideos.pending]: (state) => {
      state.isLoading = true;
      state.error = null;
    },
    [fetchVideos.fulfilled]: (state, { payload }) => {
      state.videos = payload;
      state.isLoading = false;
    },
    [fetchVideos.rejected]: (state, { payload }) => {
      state.isLoading = false;
      state.error = payload;
    },
    [fetchVideo.pending]: (state) => {
      state.isLoading = true;
      state.error = null;
    },
    [fetchVideo.fulfilled]: (state, { payload }) => {
      state.video = payload;
      state.isLoading = false;
    },
    [fetchVideo.rejected]: (state, { payload }) => {
      state.isLoading = false;
      state.error = payload;
    },
    [fetchPlatform.pending]: (state) => {
      state.isLoading = true;
      state.error = null;
    },
    [fetchPlatform.fulfilled]: (state, { payload }) => {
      state.platform = payload;
      state.isLoading = false;
    },
    [fetchPlatform.rejected]: (state, { payload }) => {
      state.isLoading = false;
      state.error = payload;
    },
    [fetchFilms.pending]: (state) => {
      state.isLoading = true;
      state.error = null;
    },
    [fetchFilms.fulfilled]: (state, { payload }) => {
      state.films = payload;
      state.isLoading = false;
    },
    [fetchFilms.rejected]: (state, { payload }) => {
      state.isLoading = false;
      state.error = payload;
    },
    [fetchFilm.pending]: (state) => {
      state.isLoading = true;
      state.error = null;
    },
    [fetchFilm.fulfilled]: (state, { payload }) => {
      state.film = payload.length ? payload[0] : null;
      state.isLoading = false;
    },
    [fetchFilm.rejected]: (state, { payload }) => {
      state.isLoading = false;
      state.error = payload;
    },
    [fetchOrganizerEvents.pending]: (state) => {
      state.isLoading = true;
      state.error = null;
    },
    [fetchOrganizerEvents.fulfilled]: (state, { payload }) => {
      state.events = payload;
      state.isLoading = false;
    },
    [fetchOrganizerEvents.rejected]: (state, { payload }) => {
      state.isLoading = false;
      state.error = payload;
    },
    [updateFilmEvent.fulfilled]: (state, { payload }) => {
      const filmIndex = state.films.findIndex(
        (film) => film._id === payload._id
      );
      state.films[filmIndex] = { ...payload };
    },
  },
});

export const { setPlatform, setFilm, setIsLoading, setError, setFilmState } =
  videosSlice.actions;

export default videosSlice.reducer;
