import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { me } from 'store/reducers/auth/action';
import store from 'store';
import { SocketProvider } from 'context/WebSocket';
import { fetchConversations } from 'store/reducers/chat/actions';
import { GoogleOAuthProvider } from '@react-oauth/google';

// import HelmetMetaData from 'components/Shared/HelmetMetaData';
import Routes from './routes';

const App = () => {
  const { currentUser, token } = useSelector((state) => state.auth);

  useEffect(() => {
    if (!currentUser && token) {
      const { dispatch } = store;
      dispatch(me(token));
    }
  }, []);

  const { conversations } = useSelector((state) => state.chat);

  const dispatch = useDispatch();
  useEffect(() => {
    if (currentUser && token) {
      if (!conversations.length) {
        dispatch(fetchConversations({ user_id: currentUser._id }));
      }
    }
  }, [token, currentUser]);

  return (
    <SocketProvider>
      {/* <HelmetMetaData /> */}
      <GoogleOAuthProvider clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID}>
        <Routes />
      </GoogleOAuthProvider>
    </SocketProvider>
  );
};

export default App;
