import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';

const UnathenticatedRoute = ({ component: Component, ...rest }) => {
  const { token } = useSelector((state) => state.auth);
  const { computedMatch } = rest;
  const { params } = computedMatch;
  const { watch } = params;

  return (
    <Route
      {...rest}
      render={(props) => {
        switch (!!token) {
          case false:
            return <Component {...props} />;
          default:
            return watch === 'watch' ? (
              <Redirect to="/watch" />
            ) : watch ? (
              <Redirect to={`/watch/${watch}`} />
            ) : (
              <Redirect to="/" />
            );
        }
      }}
    />
  );
};

UnathenticatedRoute.propTypes = {
  component: PropTypes.objectOf(PropTypes.any).isRequired,
};

export default UnathenticatedRoute;
