import { createAsyncThunk } from '@reduxjs/toolkit';
import {
  loginRequest,
  meRequest,
  registerRequest,
  loginWithFacebookRequest,
  forgetPassword,
  resetPasswordRequest,
  updateProfileRequest,
  uploadRequest,
  updatePasswordRequest,
  registerGoogleRequest,
  loginGoogleRequest,
  registerFacebookRequest,
  loginFacebookRequest,
  changeUserWelcomeRequest,
  deleteProfileImageRequest,
  accountDeleteRequest,
  registerAppleRequest,
  loginAppleRequest,
} from './apis';

export const me = createAsyncThunk(
  'auth/me',
  async (token, { rejectWithValue }) => {
    try {
      const res = await meRequest(token);
      const currentUser = res.data.data;
      localStorage.setItem('token', token);
      return currentUser;
    } catch (error) {
      return rejectWithValue(error.response.data.message);
    }
  }
);

export const register = createAsyncThunk(
  'auth/register',
  async (data, { rejectWithValue, dispatch }) => {
    try {
      const res = await registerRequest(data);
      const { token } = res.data.data;
      await dispatch(me(token));
      return token;
    } catch (error) {
      return rejectWithValue(error.response.data.message);
    }
  }
);

export const registerGoogle = createAsyncThunk(
  'auth/register-google',
  async (data, { rejectWithValue, dispatch }) => {
    try {
      const res = await registerGoogleRequest(data);
      const { token } = res.data.data;
      await dispatch(me(token));
      return token;
    } catch (error) {
      return rejectWithValue(error.response.data.message);
    }
  }
);

export const registerApple = createAsyncThunk(
  'auth/register-apple',
  async (data, { rejectWithValue, dispatch }) => {
    try {
      const res = await registerAppleRequest(data);
      console.log('Res in redux : ', res.data);
      const { token } = res.data.data;
      await dispatch(me(token));
      return token;
    } catch (error) {
      return rejectWithValue(error.response.data.message);
    }
  }
);

export const registerFacebook = createAsyncThunk(
  'auth/register-facebook',
  async (data, { rejectWithValue, dispatch }) => {
    try {
      const res = await registerFacebookRequest(data);
      const { token } = res.data.data;
      await dispatch(me(token));
      return token;
    } catch (error) {
      return rejectWithValue(error.response.data.message);
    }
  }
);

export const login = createAsyncThunk(
  'auth/login',
  async (data, { rejectWithValue, dispatch }) => {
    try {
      const res = await loginRequest(data);
      const { token } = res.data.data;
      await dispatch(me(token));
      return token;
    } catch (error) {
      return rejectWithValue(error.response.data.message);
    }
  }
);

export const loginGoogle = createAsyncThunk(
  'auth/login-google',
  async (data, { rejectWithValue, dispatch }) => {
    try {
      const res = await loginGoogleRequest(data);
      const { token } = res.data.data;
      await dispatch(me(token));
      return token;
    } catch (error) {
      return rejectWithValue(error.response.data.message);
    }
  }
);

export const loginApple = createAsyncThunk(
  'auth/login-apple',
  async (data, { rejectWithValue, dispatch }) => {
    try {
      const res = await loginAppleRequest(data);
      const { token } = res.data.data;
      await dispatch(me(token));
      return token;
    } catch (error) {
      return rejectWithValue(error.response.data.message);
    }
  }
);

export const loginFacebook = createAsyncThunk(
  'auth/login-facebook',
  async (data, { rejectWithValue, dispatch }) => {
    try {
      const res = await loginFacebookRequest(data);
      const { token } = res.data.data;
      await dispatch(me(token));
      return token;
    } catch (error) {
      return rejectWithValue(error.response.data.message);
    }
  }
);

export const updateCurrentUser = createAsyncThunk(
  'auth/updateCurrentUser',
  async (_, { rejectWithValue }) => {
    try {
      const token = localStorage.getItem('token');
      const res = await meRequest(token);
      return res.data.data;
    } catch (error) {
      return rejectWithValue(error.response.data.message);
    }
  }
);

export const updateProfile = createAsyncThunk(
  'auth/updateProfile',
  async (data) => {
    const res = await updateProfileRequest(data);
    return res.data.data;
  }
);

export const changeUserWelcome = createAsyncThunk('auth/welcome', async () => {
  const res = await changeUserWelcomeRequest();
  return res.data.data;
});

export const loginWithFacebook = createAsyncThunk(
  'auth/loginWithFacebook',
  async () => {
    await loginWithFacebookRequest();
  }
);

export const forgotPassword = createAsyncThunk(
  'auth/forget-password',
  async (payload, { rejectWithValue }) => {
    try {
      const res = await forgetPassword(payload);
      return res.data.data;
    } catch (error) {
      return rejectWithValue(error.response.data.message);
    }
  }
);
export const resetPassword = createAsyncThunk(
  'auth/change-password',
  async (payload, { rejectWithValue }) => {
    try {
      const res = await resetPasswordRequest(payload);
      return res.data.data;
    } catch (error) {
      return rejectWithValue(error.response.data.message);
    }
  }
);

export const uploadFile = createAsyncThunk(
  'auth/uploadFile',
  async ({ _id, file }, { dispatch }) => {
    const res = await uploadRequest(file);
    await dispatch(
      updateProfile({
        avatar: res.data.imageUrl,
        _id,
      })
    );
    return res.data.imageUrl;
  }
);

export const updatePassword = createAsyncThunk(
  'auth/updatePassword',
  async (data) => {
    const res = await updatePasswordRequest(data);
    return res.data.data;
  }
);

export const accountDelete = createAsyncThunk(
  'notification/accountDelete',
  async () => {
    const res = await accountDeleteRequest();
    return res.data.data;
  }
);

export const deleteProfileImage = createAsyncThunk(
  'auth/deleteProfileImage',
  async () => {
    const res = await deleteProfileImageRequest();
    return res.data.data;
  }
);
