import axiosInstance from '../../../helpers/axios';

export const getPins = (query) => {
  return axiosInstance.get(`pin/get_by_query${query}`);
};

export const postPin = (data) => {
  return axiosInstance.post('pin/store', data);
};

export const patchPin = ({ pinId, data }) => {
  return axiosInstance.patch(`pin/${pinId}`, data);
};

export const deletePin = (pinId) => {
  return axiosInstance.delete(`pin/${pinId}`);
};
