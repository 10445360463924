/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';
import chargeWallet, {
  addTransaction,
  chargeFreeWallet,
  getTransactionLogs,
  getTransactions,
  getWallet,
  updateWallet,
} from './action';

export const WalletSlice = createSlice({
  name: 'wallet',
  initialState: {
    wallet: '0',
    creatorWallet: null,
    transactions: [],
    logs: [],
    isLoading: false,
    isLogLoading: false,
    error: null,
  },
  reducers: {
    clearError: (state) => {
      state.error = null;
    },
  },
  extraReducers: {
    [chargeWallet.pending]: (state) => {
      state.isLoading = true;
      state.error = null;
    },
    [chargeWallet.fulfilled]: (state, { payload }) => {
      state.wallet = payload;
      state.isLoading = false;
      state.error = null;
    },
    [chargeWallet.rejected]: (state, { payload }) => {
      state.isLoading = false;
      state.error = payload;
    },
    [chargeFreeWallet.pending]: (state) => {
      state.isLoading = true;
      state.error = null;
    },
    [chargeFreeWallet.fulfilled]: (state, { payload }) => {
      state.wallet = payload;
      state.isLoading = false;
      state.error = null;
    },
    [chargeFreeWallet.rejected]: (state, { payload }) => {
      state.isLoading = false;
      state.error = payload;
    },
    [getWallet.pending]: (state) => {
      state.isLoading = true;
      state.error = null;
    },
    [getWallet.fulfilled]: (state, { payload }) => {
      state.creatorWallet = payload;
      state.isLoading = false;
    },
    [getWallet.rejected]: (state, { payload }) => {
      state.isLoading = false;
      state.error = payload;
    },
    [updateWallet.pending]: (state) => {
      state.isLoading = true;
      state.error = null;
    },
    [updateWallet.fulfilled]: (state, { payload }) => {
      state.creatorWallet = payload;
      state.isLoading = false;
    },
    [updateWallet.rejected]: (state, { payload }) => {
      state.isLoading = false;
      state.error = payload;
    },
    [getTransactions.pending]: (state) => {
      state.isLoading = true;
      state.error = null;
    },
    [getTransactions.fulfilled]: (state, { payload }) => {
      state.transactions = payload;
      state.isLoading = false;
    },
    [getTransactions.rejected]: (state, { payload }) => {
      state.isLoading = false;
      state.error = payload;
    },
    [getTransactionLogs.pending]: (state) => {
      state.isLogLoading = true;
      state.error = null;
    },
    [getTransactionLogs.fulfilled]: (state, { payload }) => {
      state.logs = payload;
      state.isLogLoading = false;
    },
    [getTransactionLogs.rejected]: (state, { payload }) => {
      state.isLogLoading = false;
      state.error = payload;
    },
    [addTransaction.pending]: (state) => {
      state.isLoading = true;
      state.error = null;
    },
    [addTransaction.fulfilled]: (state, { payload }) => {
      state.transactions = [payload.transactions, ...state.transactions];
      state.creatorWallet = payload.wallet;
      state.isLoading = false;
    },
    [addTransaction.rejected]: (state, { payload }) => {
      state.isLoading = false;
      state.error = payload;
    },
  },
});

export const { clearError } = WalletSlice.actions;

export default WalletSlice.reducer;
