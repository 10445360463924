import { createAsyncThunk } from '@reduxjs/toolkit';
import {
  fetchVideosRequest,
  fetchVideoRequest,
  fetchPlatformRequest,
  fetchPlatformsRequest,
  fetchFilmsRequest,
  updateFilmEventRequest,
  uploadRequest,
  storeFilmRequest,
  fetchFilmRequest,
  fetchOrganizerEventsRequest,
} from './apis';

export const fetchFilms = createAsyncThunk(
  'videos/fetchFilms',
  async (query, { rejectWithValue }) => {
    try {
      const res = await fetchFilmsRequest(query);

      return res.data.data;
    } catch (error) {
      return rejectWithValue(error.response.data.message);
    }
  }
);

export const fetchFilm = createAsyncThunk(
  'videos/fetchFilm',
  async (query, { rejectWithValue }) => {
    try {
      const res = await fetchFilmRequest(query);
      return res.data.data;
    } catch (error) {
      return rejectWithValue(error.response.data.message);
    }
  }
);

export const fetchVideos = createAsyncThunk(
  'videos/fetchVideos',
  async ({ chapterId }, { rejectWithValue }) => {
    try {
      const res = await fetchVideosRequest(chapterId);
      return res.data.data;
    } catch (error) {
      return rejectWithValue(error.response.data.message);
    }
  }
);

export const fetchVideo = createAsyncThunk(
  'videos/fetchVideo',
  async ({ videoId }, { rejectWithValue }) => {
    try {
      const res = await fetchVideoRequest(videoId);
      return res.data.data[0];
    } catch (error) {
      return rejectWithValue(error.response.data.message);
    }
  }
);

export const fetchVideosAndPlatforms = createAsyncThunk(
  'videos/fetchVideosAndPlatforms',
  async ({ chapterId }, { rejectWithValue }) => {
    try {
      const res = await fetchVideosRequest(chapterId);
      const res2 = await fetchPlatformsRequest(chapterId);

      return { videos: res.data.data, platforms: res2.data.data };
    } catch (error) {
      return rejectWithValue(error.response.data.message);
    }
  }
);

export const fetchPlatform = createAsyncThunk(
  'videos/fetchPlatform',
  async ({ platformId }, { rejectWithValue }) => {
    try {
      const res = await fetchPlatformRequest(platformId);
      return res.data.data[0];
    } catch (error) {
      return rejectWithValue(error.response.data.message);
    }
  }
);

export const fetchOrganizerEvents = createAsyncThunk(
  'videos/fetchOrganizerEvents',
  async () => {
    const res = await fetchOrganizerEventsRequest();
    return res.data.data;
  }
);

export const uploadFile = createAsyncThunk(
  'videos/uploadFile',
  async (payload) => {
    const res = await uploadRequest(payload);
    return res.data;
  }
);

export const storeFilm = createAsyncThunk('videos/storeFilm', async (data) => {
  const res = await storeFilmRequest(data);
  return res.data.data;
});

export const updateFilmEvent = createAsyncThunk(
  'videos/updateFilmEvent',
  async (data) => {
    const res = await updateFilmEventRequest(data);
    return res.data.data;
  }
);
