/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';
import fetchSurprises from './action';

export const surprisesSlice = createSlice({
  name: 'surprise',
  initialState: {
    surprises: [],
    isLoading: true,
    error: null,
  },
  reducers: {},
  extraReducers: {
    [fetchSurprises.pending]: (state) => {
      state.isLoading = true;
      state.error = null;
    },
    [fetchSurprises.fulfilled]: (state, { payload }) => {
      state.surprises = payload;
      state.isLoading = false;
    },
    [fetchSurprises.rejected]: (state, { payload }) => {
      state.isLoading = false;
      state.error = payload;
    },
  },
});

export const { setSurprises } = surprisesSlice.actions;
export default surprisesSlice.reducer;
