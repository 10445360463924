import axiosInstance from '../../../helpers/axios';

export const fetchFilmsRequest = (query) => {
  return axiosInstance.get(`video/get_by_query${query ? `?${query}` : ''}`);
};

export const fetchFilmRequest = (query) => {
  return axiosInstance.get(`video/get_by_query${query ? `?${query}` : ''}`);
};

export const fetchVideosRequest = (chapterId) => {
  return axiosInstance.get(`video/get_by_query?feature_id=${chapterId}`);
};

export const fetchVideoRequest = (videoId) => {
  return axiosInstance.get(`video/get_by_query?video_id=${videoId}`);
};

export const fetchPlatformsRequest = (chapterId) => {
  return axiosInstance.get(`streaming/get_by_query?feature_id=${chapterId}`);
};

export const fetchPlatformRequest = (platformId) => {
  return axiosInstance.get(`streaming/get_by_query?streaming_id=${platformId}`);
};
export const fetchOrganizerEventsRequest = () => {
  return axiosInstance.get(`video/get_by_organizer`);
};

export const uploadRequest = (data) => {
  return axiosInstance.post('auth/upload', data);
};

export const storeFilmRequest = (data) => {
  return axiosInstance.post('video/store', data);
};

export const updateFilmEventRequest = ({ id, data }) => {
  return axiosInstance.patch(`video/${id}`, data);
};
