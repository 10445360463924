import { createSlice, createEntityAdapter } from '@reduxjs/toolkit';
import { fetchFeedbacks, storeFeedback, updateFeedback } from './action';

export const feedbackAdapter = createEntityAdapter({
  selectId: (feedback) => feedback._id,
  sortComparer: (a, b) =>
    new Date(b.dynamic.updated_at) - new Date(a.dynamic.updated_at),
});

const initialState = feedbackAdapter.getInitialState({
  status: 'idle', // 'idle' | 'loading' | 'succeeded' | 'failed'
});

export const lessonsSlice = createSlice({
  name: 'feedback',
  initialState,
  reducers: {
    setLoadingStatus: (state) => {
      state.status = true;
    },
    resetFeedback: (state) => {
      feedbackAdapter.removeAll(state);
    },
  },
  extraReducers: {
    [fetchFeedbacks.pending]: (state) => {
      state.status = 'loading';
    },
    [fetchFeedbacks.fulfilled]: (state, { payload }) => {
      feedbackAdapter.upsertMany(state, payload);
      state.status = 'succeeded';
    },
    [fetchFeedbacks.rejected]: (state) => {
      state.status = 'failed';
    },
    [storeFeedback.fulfilled]: (state, { payload }) => {
      feedbackAdapter.addOne(state, payload);
    },
    [updateFeedback.fulfilled]: (state, { payload }) => {
      feedbackAdapter.setOne(state, payload);
    },
  },
});

export const { selectAll: selectAllFeedback, selectById: selectFeedbackById } =
  feedbackAdapter.getSelectors((state) => state.feedback);

export const selectFeedbackStatus = (state) => state.feedback.status;

export const { setLoadingStatus, resetFeedback } = lessonsSlice.actions;

export default lessonsSlice.reducer;
