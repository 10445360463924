/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';
import { fetchPins, createPin, updatePin, removePin } from './action';

export const pinSlice = createSlice({
  name: 'pin',
  initialState: {
    pins: [],
    isLoading: true,
    error: null,
  },
  reducers: {},
  extraReducers: {
    [fetchPins.pending]: (state) => {
      state.isLoading = true;
      state.error = null;
    },
    [fetchPins.fulfilled]: (state, { payload }) => {
      state.pins = payload;
      state.isLoading = false;
    },
    [fetchPins.rejected]: (state) => {
      state.isLoading = false;
      state.error = true;
    },
    [createPin.fulfilled]: (state, { payload }) => {
      state.pins.push(payload);
    },
    [updatePin.fulfilled]: (state, { payload }) => {
      const findBoard = state.pins.find((pin) => pin._id === payload._id);
      Object.assign(findBoard, payload);
    },
    [removePin.fulfilled]: (state, { payload }) => {
      const newPins = state.pins.filter((pin) => pin._id !== payload);
      state.pins = newPins;
    },
  },
});

export default pinSlice.reducer;
