import { createAsyncThunk } from '@reduxjs/toolkit';
import { fetchChaptersRequest, fetchChapterRequest } from './apis';

const generateBg = (title) => {
  switch (title) {
    case 'watch-it':
      return 'plum';
    case 'analyse-it':
      return 'rob-roy';
    case 'curate-it':
      return 'juniper';
    case 'finance-it':
      return 'cranberry';
    case 'market-it':
      return 'azure';
    case 'execute-it':
      return 'polo-blue';
    case 'evaluate-it':
      return 'moody-blue';
    default:
      return 'heliotrope';
  }
};

export const fetchChapters = createAsyncThunk(
  'chapter/fetchChapters',
  async (_, { rejectWithValue }) => {
    try {
      const res = await fetchChaptersRequest();
      return res.data.data.map((chapter) => {
        return {
          ...chapter,
          bg: generateBg(chapter.title.toLowerCase()),
          url: chapter.title.toLowerCase(),
          title: chapter.title.toLowerCase(),
        };
      });
    } catch (error) {
      return rejectWithValue(error.response.data.message);
    }
  }
);

export const fetchChapter = createAsyncThunk(
  'chapter/fetchChapter',
  async (chapterId, { rejectWithValue }) => {
    try {
      const res = await fetchChapterRequest(chapterId);
      const chapter = res.data.data;
      return {
        ...res.data.data,
        bg: generateBg(chapter.title.toLowerCase()),
        url: chapter.title.toLowerCase(),
        title: chapter.title.toLowerCase(),
      };
    } catch (error) {
      return rejectWithValue(error.response.data.message);
    }
  }
);
