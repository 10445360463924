/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';
import { fetchChapters, fetchChapter } from './action';

export const chaptersSlice = createSlice({
  name: 'chapters',
  initialState: {
    chapters: [],
    chapter: null,
    isLoading: true,
    error: null,
  },
  reducers: {
    setIsLoading: (state, { payload }) => {
      state.isLoading = payload;
    },
    setChapter: (state, { payload }) => {
      state.chapter = { ...state.chapter, description: payload.description };
    },
    setError: (state, { payload }) => {
      state.error = payload;
    },
  },
  extraReducers: {
    [fetchChapters.pending]: (state) => {
      state.isLoading = true;
      state.error = null;
    },
    [fetchChapters.fulfilled]: (state, { payload }) => {
      state.chapters = payload;
      state.isLoading = false;
    },
    [fetchChapters.rejected]: (state, { payload }) => {
      state.isLoading = false;
      state.error = payload;
    },
    [fetchChapter.pending]: (state) => {
      state.isLoading = true;
      state.error = null;
    },
    [fetchChapter.fulfilled]: (state, { payload }) => {
      state.chapter = payload;
      state.isLoading = false;
    },
    [fetchChapter.rejected]: (state, { payload }) => {
      state.isLoading = false;
      state.error = payload;
    },
  },
});

export const { setIsLoading, setChapter, setError } = chaptersSlice.actions;

export default chaptersSlice.reducer;
