import axiosInstance from '../../../helpers/axios';

export const getCategories = () => {
  return axiosInstance.get('category/get_by_query');
};

export const postCategories = (data) => {
  return axiosInstance.post('category/store', data);
};

export const patchCategories = (data) => {
  return axiosInstance.patch(`category/${data._id}`, data);
};

export const deleteCategories = (categoryId) => {
  return axiosInstance.delete(`category/${categoryId}`);
};
