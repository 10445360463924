/* eslint-disable react/prop-types */
import React, { createContext, useContext, useEffect, useState } from 'react';
import io from 'socket.io-client';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { setConversations } from 'store/reducers/chat/reducer';
import {
  Avatar,
  Box,
  HStack,
  useToast,
  Text,
  CloseButton,
} from '@chakra-ui/react';

export const SocketContext = createContext();

export function useSocket() {
  return useContext(SocketContext);
}

const ENDPOINT = process.env.REACT_APP_WEBSOCKET;

export const SocketProvider = ({ children }) => {
  const [socket, setSocket] = useState();
  const { token } = useSelector((state) => state.auth);

  useEffect(() => {
    if (token) {
      const { id } = JSON.parse(atob(token.split('.')[1]));
      const newSocket = io(ENDPOINT, { query: `userId=${id}` });
      setSocket(newSocket);
      return () => {
        newSocket.disconnect();
        newSocket.off();
      };
    }
    return () => {};
  }, [token]);

  const dispatch = useDispatch();

  const toast = useToast();

  const PATH = process.env.REACT_APP_S3_IMAGE_PATH;

  useEffect(() => {
    if (socket == null) return;
    socket.on('data', (messageData) => {
      dispatch(setConversations(messageData));
      if (window.location.pathname !== '/chat') {
        toast({
          render: ({ onClose }) => (
            <Box
              color="white"
              bg="cranberry.500"
              rounded="md"
              maxW="300px"
              pos="relative"
            >
              <HStack alignItems="flex-start" py={3} pl="4" pr="8">
                <Avatar
                  name={`${messageData.sender_name}`}
                  src={`${PATH}/${messageData.sender_avatar}`}
                />
                <Box>
                  <Box
                    fontSize="lg"
                    as="h5"
                    fontWeight="semibold"
                    noOfLines="1"
                    textTransform="capitalize"
                  >
                    {messageData.sender_name}
                  </Box>
                  <Text fontSize="md" noOfLines="2">
                    {messageData.message}
                  </Text>
                </Box>
              </HStack>
              <CloseButton
                pos="absolute"
                right="1"
                top="1"
                size="sm"
                onClick={onClose}
              />
            </Box>
          ),
          status: 'warning',
          duration: 9000,
          isClosable: true,
          position: 'bottom-right',
        });
      }
    });
  }, [socket]);

  return (
    <SocketContext.Provider value={socket}>{children}</SocketContext.Provider>
  );
};

SocketProvider.propTypes = {
  children: PropTypes.objectOf(PropTypes.any).isRequired,
};
