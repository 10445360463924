import { combineReducers, configureStore } from '@reduxjs/toolkit';
import authReducer from './reducers/auth/reducer';
import walletReducer from './reducers/wallet/reducer';
import chapterReducer from './reducers/chapter/reducer';
import videoReducer from './reducers/video/reducer';
import lessonReducer from './reducers/lesson/reducer';
import notebookReducer from './reducers/notebook/reducer';
import userReducer from './reducers/user/reducer';
import teamReducer from './reducers/team/reducer';
import chatReducer from './reducers/chat/reducer';
import boardReducer from './reducers/board/reducer';
import pinReducer from './reducers/pin/reducer';
import categoryReducer from './reducers/category/reducer';
import countries from './reducers/country/reducer';
import configReducer from './reducers/config/reducer';
import purchase from './reducers/purchase/reducer';
import feedback from './reducers/feedback/reducer';
import films from './reducers/films/reducer';
import surpriseReducer from './reducers/surprise/reducer';

const combinedReducer = combineReducers({
  auth: authReducer,
  wallet: walletReducer,
  chapter: chapterReducer,
  video: videoReducer,
  lesson: lessonReducer,
  surprise: surpriseReducer,
  notebook: notebookReducer,
  user: userReducer,
  team: teamReducer,
  chat: chatReducer,
  board: boardReducer,
  pin: pinReducer,
  categories: categoryReducer,
  configs: configReducer,
  countries,
  purchase,
  feedback,
  films,
});

const rootReducer = (state, action) => {
  if (action.type === 'auth/logout') {
    state = {};
  }
  return combinedReducer(state, action);
};

export default configureStore({
  reducer: rootReducer,
  devTools: process.env.NODE_ENV !== 'production',
});
