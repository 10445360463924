import { createSlice, createEntityAdapter } from '@reduxjs/toolkit';
import { fetchConfigs } from './action';

export const configsAdapter = createEntityAdapter({
  selectId: (config) => config._id,
});

const initialState = configsAdapter.getInitialState({
  status: 'idle', // 'idle' | 'loading' | 'succeeded' | 'failed'
});

export const lessonsSlice = createSlice({
  name: 'configs',
  initialState,
  reducers: {},
  extraReducers: {
    [fetchConfigs.pending]: (state) => {
      state.isLoading = 'loading';
    },
    [fetchConfigs.fulfilled]: (state, { payload }) => {
      configsAdapter.upsertMany(state, payload);
      state.isLoading = 'succeeded';
    },
    [fetchConfigs.rejected]: (state) => {
      state.isLoading = 'failed';
    },
  },
});

export const { selectAll: selectAllConfigs } = configsAdapter.getSelectors(
  (state) => state.configs
);

export const selectConfigsStatus = (state) => state.configs.status;

export default lessonsSlice.reducer;
