/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';
import { fetchConversations } from './actions';

export const chatSlice = createSlice({
  name: 'chat',
  initialState: {
    conversations: [],
    selectedConversation: {},
    isLoading: true,
    error: null,
  },
  reducers: {
    // setConversations: (state, { payload }) => {
    //   const { random_id: randomId, data } = payload;
    //   const conversationIndex = state.conversations.findIndex(
    //     (conversation) => conversation.random_id === randomId
    //   );
    //   if (conversationIndex > -1) {
    //     state.conversations[conversationIndex] = data;
    //   } else {
    //     state.conversations.push(data);
    //   }
    //   // state.conversations
    //   //   .find((conversation) => conversation.random_id === randomId)
    //   //   .history.push(rest);
    // },
    // setConversations: (state, { payload }) => {
    //   const { currentUser, messageData, users } = payload;

    //   const conversationIndex = state.conversations.findIndex(
    //     (conversation) => conversation.random_id === messageData.random_id
    //   );

    //   if (conversationIndex > -1) {
    //     state.conversations[conversationIndex].history.push(messageData);
    //   } else {
    //     const userReciever = users.find(
    //       (user) => user._id === messageData.user_receive_id
    //     );

    //     const data = {
    //       random_id: messageData.random_id,
    //       user_id: currentUser,
    //       user_receive_id: userReciever,
    //       history: [messageData],
    //     };

    //     state.conversations.push(data);
    //   }
    //   // state.conversations
    //   //   .find((conversation) => conversation.random_id === randomId)
    //   //   .history.push(rest);
    // },
    // setConversation: (state, { payload }) => {
    //   state.conversation = state.conversations.length
    //     ? state.conversations.find((conversation) =>
    //         [
    //           conversation.user_receive_id._id,
    //           conversation.user_id._id,
    //         ].includes(payload)
    //       ) || {}
    //     : {};
    // },
    setConversations: (state, { payload }) => {
      const conversationIndex = state.conversations.findIndex((conversation) =>
        [
          payload.sender_id + payload.reciever_id,
          payload.reciever_id + payload.sender_id,
        ].includes(conversation.thread)
      );
      if (conversationIndex > -1) {
        state.conversations[conversationIndex].history.push(payload);
      } else {
        state.conversations.push({
          history: [payload],
          thread: payload.thread,
        });
      }
    },
    setSelectedConversation: (state, { payload }) => {
      const conversation = state.conversations.find(
        (item) => item.thread === payload
      );
      state.selectedConversation = conversation || {};
    },
    clearConversations: (state) => {
      state.conversations = [];
    },
  },
  extraReducers: {
    [fetchConversations.pending]: (state) => {
      state.error = null;
    },
    [fetchConversations.fulfilled]: (state, { payload }) => {
      state.conversations = payload;
      state.isLoading = false;
    },
    [fetchConversations.rejected]: (state, { payload }) => {
      state.isLoading = false;
      state.error = payload;
    },
  },
});

export const { setConversations, setSelectedConversation, clearConversations } =
  chatSlice.actions;

export default chatSlice.reducer;
