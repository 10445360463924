import { createAsyncThunk } from '@reduxjs/toolkit';
import { getPins, postPin, patchPin, deletePin } from './apis';

export const fetchPins = createAsyncThunk(
  'pin/fetchPins',
  async (query, { rejectWithValue }) => {
    try {
      const res = await getPins(query);
      return res.data.data;
    } catch (error) {
      return rejectWithValue(error.response.data.message);
    }
  }
);

export const createPin = createAsyncThunk('pin/createPin', async (data) => {
  const res = await postPin(data);
  return res.data.data;
});

export const updatePin = createAsyncThunk('pin/updatePin', async (data) => {
  const res = await patchPin(data);
  return res.data.data;
});

export const removePin = createAsyncThunk('pin/deletePin', async (pinId) => {
  await deletePin(pinId);
  return pinId;
});
