import { createAsyncThunk } from '@reduxjs/toolkit';
import {
  postNotebook,
  getNotebooks,
  patchNotebook,
  getNotebook,
  deleteNotebook,
} from './apis';

export const storeNotebook = createAsyncThunk(
  'notebook/storeNotebook',
  async (payload) => {
    const res = await postNotebook(payload);
    return res.data.data;
  }
);

export const updateNotebook = createAsyncThunk(
  'notebook/updateNotebook',
  async (payload) => {
    const res = await patchNotebook(payload);
    return res.data.data;
  }
);

export const fetchNotebooks = createAsyncThunk(
  'notebook/fetchNotebooks',
  async ({ userId }, { rejectWithValue }) => {
    try {
      const res = await getNotebooks(userId);
      return res.data.data.reduce((accum, curr) => {
        return {
          ...accum,
          [curr.type]: res.data.data.filter((item) => item.type === curr.type),
        };
      }, {});
    } catch (error) {
      return rejectWithValue(error.response.data.message);
    }
  }
);

export const fetchNotebook = createAsyncThunk(
  'notebook/fetchNotebook',
  async ({ userId }, { rejectWithValue }) => {
    try {
      const res = await getNotebook(userId);
      return res.data.data.reduce((accum, curr) => {
        return {
          ...accum,
          [curr.type]: res.data.data.filter((item) => item.type === curr.type),
        };
      }, {});
    } catch (error) {
      return rejectWithValue(error.response.data.message);
    }
  }
);

export const removeNotebook = createAsyncThunk(
  'notebook/removeNotebook',
  async (notebookId) => {
    const res = await deleteNotebook(notebookId);
    return res.data.data;
  }
);
