import { createAsyncThunk } from '@reduxjs/toolkit';
import { getCountries } from './api';

export const fetchCountries = createAsyncThunk(
  'countries/fetchCountries',
  async (_, { rejectWithValue }) => {
    try {
      const res = await getCountries();
      return res.data.data;
    } catch (error) {
      return rejectWithValue(error.response.data.message);
    }
  }
);
