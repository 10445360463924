import { createSlice, createEntityAdapter } from '@reduxjs/toolkit';
import {
  createCategory,
  fetchCategories,
  removeCategory,
  updateCategory,
} from './action';

export const categoriesAdapter = createEntityAdapter({
  selectId: (caregory) => caregory._id,
});

const initialState = categoriesAdapter.getInitialState({
  status: 'idle', // 'idle' | 'loading' | 'succeeded' | 'failed'
});

export const gategoriesSlice = createSlice({
  name: 'categories',
  initialState,
  reducers: {},
  extraReducers: {
    [fetchCategories.pending]: (state) => {
      state.status = 'loading';
    },
    [fetchCategories.fulfilled]: (state, action) => {
      state.status = 'succeeded';
      categoriesAdapter.upsertMany(state, action.payload);
    },
    [fetchCategories.rejected]: (state, action) => {
      state.status = 'failed';
      state.error = action.payload;
    },
    [createCategory.fulfilled]: (state, action) => {
      categoriesAdapter.addOne(state, action.payload);
    },
    [updateCategory.fulfilled]: (state, { payload }) => {
      const { _id: id, ...changes } = payload;
      categoriesAdapter.updateOne(state, { id, changes });
    },
    [removeCategory.fulfilled]: (state, action) => {
      categoriesAdapter.removeOne(state, action.payload._id);
    },
  },
});

export const { selectAll: selectAllCategories } =
  categoriesAdapter.getSelectors((state) => state.categories);

export const selectCategoriesStatus = (state) => state.categories.status;

export default gategoriesSlice.reducer;
