import { createAsyncThunk } from '@reduxjs/toolkit';
import { getConfigs } from './api';

export const fetchConfigs = createAsyncThunk(
  'configs/fetchConfigs',
  async (_, { rejectWithValue }) => {
    try {
      const res = await getConfigs();
      return res.data.data;
    } catch (error) {
      return rejectWithValue(error.response.data.message);
    }
  }
);
