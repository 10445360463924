import { createAsyncThunk } from '@reduxjs/toolkit';
import {
  chargeWalletRequest,
  chargeStripeRequest,
  preStoreRequest,
  postStoreRequest,
  chargeFreeRequest,
  getCreaterWallet,
  moveMoneyToReview,
  getTransactionsRequest,
  newTransactionRequest,
  getTransactionLogsRequest,
} from './apis';
import { updateCurrentUser } from '../auth/action';

const chargeWallet = createAsyncThunk(
  'wallet/chargeWallet',
  async ({ data, charge }, { rejectWithValue, dispatch }) => {
    try {
      await chargeStripeRequest(charge);
      if (data) {
        await chargeWalletRequest(data);
      }
      await dispatch(updateCurrentUser());
      return true;
    } catch (error) {
      return rejectWithValue(error.response.data?.data?.raw?.message);
    }
  }
);

export const getWallet = createAsyncThunk(
  'creator-wallet/id',
  async (_, { rejectWithValue }) => {
    try {
      const res = await getCreaterWallet();
      return res.data.data;
    } catch (error) {
      return rejectWithValue(error.response.data.message);
    }
  }
);

export const getTransactions = createAsyncThunk(
  'transaction/id',
  async (_, { rejectWithValue }) => {
    try {
      const res = await getTransactionsRequest();
      return res.data.data;
    } catch (error) {
      return rejectWithValue(error.response.data.message);
    }
  }
);

export const getTransactionLogs = createAsyncThunk(
  'transaction/log',
  async (payload, { rejectWithValue }) => {
    try {
      const res = await getTransactionLogsRequest(payload);
      console.log(res.data.data);
      return res.data.data;
    } catch (error) {
      return rejectWithValue(error.response.data.message);
    }
  }
);

export const addTransaction = createAsyncThunk(
  'transaction/new',
  async (_, { rejectWithValue }) => {
    try {
      const res = await newTransactionRequest();
      return res.data.data;
    } catch (error) {
      return rejectWithValue(error.response.data.message);
    }
  }
);

export const updateWallet = createAsyncThunk(
  'creator-wallet/update',
  async (_, { rejectWithValue }) => {
    try {
      const res = await moveMoneyToReview();
      console.log(res.data.data);
      return res.data.data;
    } catch (error) {
      return rejectWithValue(error.response.data.message);
    }
  }
);

export const chargeFreeWallet = createAsyncThunk(
  'wallet/chargeFreeWallet',
  async ({ data, charge }, { rejectWithValue, dispatch }) => {
    try {
      await chargeFreeRequest(charge);
      if (data) {
        await chargeWalletRequest(data);
      }
      await dispatch(updateCurrentUser());
      return true;
    } catch (error) {
      return rejectWithValue(error.response.data.data.raw.message);
    }
  }
);

export const chargeWithPaypalWallet = createAsyncThunk(
  'wallet/chargeWithPaypalWallet',
  async ({ preStoreData, postStoreData }, { dispatch }) => {
    // const { team,, paid_users: emails, ...rest } = formData;
    const preRes = await preStoreRequest(preStoreData);
    const postRes = await postStoreRequest({
      key: preRes.data.data.key,
      ...postStoreData,
    });
    await dispatch(updateCurrentUser());
    return postRes.data.data;
  }
);

export default chargeWallet;
