import { createAsyncThunk } from '@reduxjs/toolkit';
import fetchLessonsRequest from './apis';

const fetchLessons = createAsyncThunk(
  'lessons/fetchLessons',
  async ({ id }, { rejectWithValue }) => {
    try {
      const res = await fetchLessonsRequest(id);
      return res.data.data;
    } catch (error) {
      return rejectWithValue(error.response.data.message);
    }
  }
);

export default fetchLessons;
