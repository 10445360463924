import { createAsyncThunk } from '@reduxjs/toolkit';
import {
  deleteCategories,
  getCategories,
  patchCategories,
  postCategories,
} from './api';

export const fetchCategories = createAsyncThunk(
  'categories/fetchCategories',
  async (_, { rejectWithValue }) => {
    try {
      const res = await getCategories();
      return res.data.data;
    } catch (error) {
      return rejectWithValue(error.response.data.message);
    }
  }
);

export const createCategory = createAsyncThunk(
  'categories/createCategory',
  async (data) => {
    const res = await postCategories(data);
    return res.data.data;
  }
);

export const updateCategory = createAsyncThunk(
  'categories/updateCategory',
  async (data) => {
    const res = await patchCategories(data);
    return res.data.data;
  }
);

export const removeCategory = createAsyncThunk(
  'categories/removeCategory',
  async (categoryId) => {
    const res = await deleteCategories(categoryId);
    return res.data.data;
  }
);
