import axiosInstance from '../../../helpers/axios';

export const getTeam = (query) => {
  return axiosInstance.get(`team/get_by_query${query ? `?${query}` : ''}`);
};

export const getUserTeam = (query) => {
  return axiosInstance.get(`team${query ? `?${query}` : ''}`);
};

export const postTeam = (payload) => {
  return axiosInstance.post('team/store', payload);
};
