import axiosInstance from '../../../helpers/axios';

const fetchChaptersRequest = () => {
  return axiosInstance.get('feature');
};

const fetchChapterRequest = (chapterId) => {
  return axiosInstance.get(`feature/get_by_id?feature_id=${chapterId}`);
};

export { fetchChaptersRequest, fetchChapterRequest };
