import { createAsyncThunk } from '@reduxjs/toolkit';
import { getTeam, getUserTeam, postTeam } from './apis';

export const fetchTeam = createAsyncThunk(
  'team/fetchTeam',
  async (query, { rejectWithValue }) => {
    try {
      const res = await getTeam(query);
      return res.data.data;
    } catch (error) {
      return rejectWithValue(error.response.data.message);
    }
  }
);

export const fetchUserTeam = createAsyncThunk(
  'team/fetchUserTeam',
  async (query, { rejectWithValue }) => {
    try {
      const res = await getUserTeam(query);
      return res.data.data;
    } catch (error) {
      return rejectWithValue(error.response.data.message);
    }
  }
);

export const storeTeam = createAsyncThunk('team/storeTeam', async (data) => {
  const res = await postTeam(data);
  return res.data.data;
});
