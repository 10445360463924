/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';
import {
  fetchNotebooks,
  removeNotebook,
  storeNotebook,
  updateNotebook,
} from './action';

export const notebooksSlice = createSlice({
  name: 'notebook',
  initialState: {
    notebooks: [],
    notebook: null,
    isLoading: true,
    error: null,
  },
  extraReducers: {
    [fetchNotebooks.pending]: (state) => {
      state.error = null;
    },
    [fetchNotebooks.fulfilled]: (state, { payload }) => {
      state.notebooks = payload;
      state.isLoading = false;
    },
    [fetchNotebooks.rejected]: (state, { payload }) => {
      state.isLoading = false;
      state.error = payload;
    },
    [storeNotebook.fulfilled]: (state, { payload }) => {
      if (!state.notebooks[payload.type]) {
        state.notebooks = {
          ...state.notebooks,
          [payload.type]: [payload],
        };
      } else if (payload.type === 'NOTE_BOOK') {
        state.notebooks[payload.type] = [
          ...state.notebooks[payload.type],
          payload,
        ];
      } else {
        const index = state.notebooks[payload.type].findIndex(
          (notebook) => notebook._id === payload._id
        );
        state.notebooks[payload.type][index] = payload;
      }
    },
    [updateNotebook.fulfilled]: (state, { payload }) => {
      const index = state.notebooks[payload.type].findIndex(
        (notebook) => notebook._id === payload._id
      );
      state.notebooks[payload.type][index] = payload;
    },
    [removeNotebook.fulfilled]: (state, { payload }) => {
      const newNotebook = state.notebooks[payload.type].filter(
        (notebook) => notebook._id !== payload._id
      );
      state.notebooks[payload.type] = newNotebook;
    },
  },
});

export default notebooksSlice.reducer;
