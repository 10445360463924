import { createEntityAdapter, createSlice } from '@reduxjs/toolkit';
// import { isEqual, isFuture } from 'date-fns';
// import startOfDay from 'date-fns/startOfDay';
import {
  fetchFilms,
  fetchFilm,
  fetchAllFilms,
  storeFilm,
  updateFilmEvent,
} from './action';

export const filmsAdapter = createEntityAdapter({
  selectId: (film) => film._id,
});

const initialState = filmsAdapter.getInitialState({
  status: 'idle', // 'idle' | 'loading' | 'succeeded' | 'failed'
});

export const filmsSlice = createSlice({
  name: 'films',
  initialState,
  reducers: {
    setStatus: (state) => {
      state.status = 'idle';
    },
  },
  extraReducers: {
    [fetchAllFilms.pending]: (state) => {
      state.status = 'loading';
    },
    [fetchAllFilms.fulfilled]: (state, { payload }) => {
      filmsAdapter.setAll(state, payload);
      state.status = 'succeeded';
    },
    [fetchAllFilms.rejected]: (state) => {
      state.status = 'failed';
    },
    [fetchFilms.pending]: (state) => {
      state.status = 'loading';
    },
    [fetchFilms.fulfilled]: (state, { payload }) => {
      const events = payload.filter((item) => item.type === 'event');

      const eventFilms = payload.reduce((accumulator, currentValue) => {
        accumulator = [...accumulator, ...currentValue.video_id];

        return accumulator;
      }, []);

      const films = payload.filter((item) => {
        const isUnderEvent = !!eventFilms.find((film) => film._id === item._id);
        return item.type === 'film' && !isUnderEvent;
      });

      filmsAdapter.setAll(state, [...events, ...films]);

      state.status = 'succeeded';
    },
    [fetchFilms.rejected]: (state) => {
      state.status = 'failed';
    },
    [fetchFilm.pending]: (state) => {
      state.status = 'loading';
    },
    [fetchFilm.fulfilled]: (state, { payload }) => {
      filmsAdapter.setAll(state, payload);
      state.status = 'succeeded';
    },
    [fetchFilm.rejected]: (state) => {
      state.status = 'failed';
    },
    [storeFilm.fulfilled]: (state, { payload }) => {
      filmsAdapter.upsertOne(state, payload);
    },
    [updateFilmEvent.fulfilled]: (state, { payload }) => {
      filmsAdapter.upsertOne(state, payload);
    },
  },
});

export const { selectAll: selectAllFilms, selectById: selectFilmById } =
  filmsAdapter.getSelectors((state) => state.films);

export const selectFilmsStatus = (state) => state.films.status;

export const { setStatus } = filmsSlice.actions;

export default filmsSlice.reducer;
