import { createAsyncThunk } from '@reduxjs/toolkit';
import fetchUsersRequest, {
  addPaymentMethodRequest,
  fetchPaymentMethodRequest,
  fetchUserRequest,
  removePaymentMethodRequest,
} from './apis';

const fetchUsers = createAsyncThunk(
  'user/fetchUsers',
  async (query, { rejectWithValue }) => {
    try {
      const res = await fetchUsersRequest(query);
      return res.data.data;
    } catch (error) {
      return rejectWithValue(error.response.data.message);
    }
  }
);

export const fetchUser = createAsyncThunk(
  'user/fetchUser',
  async ({ query, userId }, { rejectWithValue }) => {
    try {
      const res = await fetchUserRequest({ query, userId });
      return res.data.data;
    } catch (error) {
      return rejectWithValue(error.response.data.message);
    }
  }
);

export const fetchPaymentMethods = createAsyncThunk(
  'user/payment-methods',
  async (_, { rejectWithValue }) => {
    try {
      const res = await fetchPaymentMethodRequest();
      return res.data.data;
    } catch (error) {
      return rejectWithValue(error.response.data.message);
    }
  }
);

export const addPaymentMethod = createAsyncThunk(
  'user/payment-method/add',
  async (data, { rejectWithValue }) => {
    try {
      const res = await addPaymentMethodRequest(data);
      console.log('res ------ ', res.data.data);
      return res.data.data;
    } catch (error) {
      return rejectWithValue(error.response.data.message);
    }
  }
);

export const deletePaymentMethod = createAsyncThunk(
  'user/payment-method/delete',
  async (data, { rejectWithValue }) => {
    try {
      const res = await removePaymentMethodRequest(data);
      return res.data.data;
    } catch (error) {
      return rejectWithValue(error.response.data.message);
    }
  }
);

export default fetchUsers;
