import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { Center, CircularProgress } from '@chakra-ui/react';
import { differenceInMonths } from 'date-fns';

const AuthenticatedRoute = ({ component: Component, ...rest }) => {
  const { token, currentUser } = useSelector((state) => state.auth);

  const duration = (paymentPlan) => {
    if (paymentPlan === 'Curate-It plus+' || paymentPlan === 'Curate-It light')
      return 1;

    return paymentPlan?.toLowerCase() !== 'screen-it' ? 6 : 1;
  };

  const monthsLength = (subscriptionDate) =>
    differenceInMonths(new Date(), new Date(subscriptionDate));

  const isExpired = (user) => {
    if (user.team_id) {
      return (
        monthsLength(user.team_id.createdAt) >= duration(user.team_id.title)
      );
    }
    return (
      monthsLength(user.walletObj.createdAt) >= duration(user.walletObj.title)
    );
  };

  const isPaid = (user) => user.payment && !!user.walletObj;

  switch (!!token) {
    case true:
      switch (!!currentUser) {
        case true:
          return (
            <Route
              {...rest}
              render={(props) => {
                switch (isPaid(currentUser)) {
                  case true:
                    switch (isExpired(currentUser)) {
                      case true:
                        return <Redirect to="/" />;
                      default:
                        return <Component {...props} />;
                    }
                  default:
                    return <Redirect to="/" />;
                }
              }}
            />
          );
        default:
          return (
            <Center w="100vw" h="100vh">
              <CircularProgress
                isIndeterminate
                color="whiskey.500"
                thickness="4px"
              />
            </Center>
          );
      }
    default:
      return <Redirect to="/login" />;
  }
};

AuthenticatedRoute.propTypes = {
  component: PropTypes.objectOf(PropTypes.any).isRequired,
};

export default AuthenticatedRoute;
