import { createAsyncThunk } from '@reduxjs/toolkit';
import { getConversations } from './api';

export const fetchConversations = createAsyncThunk(
  'chat/fetchConversations',
  async (data, { rejectWithValue }) => {
    try {
      const res = await getConversations(data);
      return res.data.data;
    } catch (error) {
      return rejectWithValue(error.response.data.message);
    }
  }
);
