import axiosInstance from '../../../helpers/axios';

const chargeStripeRequest = (payload) => {
  return axiosInstance.post('wallet/charge', payload);
};

const chargeFreeRequest = (payload) => {
  return axiosInstance.post('wallet/free-charge', payload);
};

const chargeWalletRequest = (payload) => {
  return axiosInstance.post('wallet/store', payload);
};

const preStoreRequest = (payload) => {
  return axiosInstance.post('wallet/preStore', payload);
};

const postStoreRequest = (payload) => {
  return axiosInstance.post('wallet/postStore', payload);
};

const getCreaterWallet = () => {
  return axiosInstance.get(`creater-wallet`);
};

const moveMoneyToReview = () => {
  return axiosInstance.post(`creater-wallet`);
};

const newTransactionRequest = () => {
  return axiosInstance.post(`transaction`);
};

const getTransactionsRequest = () => {
  return axiosInstance.get(`transaction`);
};

const getTransactionLogsRequest = (payload) => {
  return axiosInstance.get(`transaction/log/${payload}`);
};

export {
  chargeWalletRequest,
  chargeFreeRequest,
  chargeStripeRequest,
  preStoreRequest,
  postStoreRequest,
  getCreaterWallet,
  moveMoneyToReview,
  newTransactionRequest,
  getTransactionsRequest,
  getTransactionLogsRequest,
};
