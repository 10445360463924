import { createSlice, createEntityAdapter } from '@reduxjs/toolkit';
import { fetchCountries } from './action';

export const countriesAdapter = createEntityAdapter({
  selectId: (country) => country._id,
});

const initialState = countriesAdapter.getInitialState({
  status: 'idle', // 'idle' | 'loading' | 'succeeded' | 'failed'
});

export const slice = createSlice({
  name: 'countries',
  initialState,
  reducers: {},
  extraReducers: {
    [fetchCountries.pending]: (state) => {
      state.status = 'loading';
    },
    [fetchCountries.fulfilled]: (state, action) => {
      state.status = 'succeeded';
      countriesAdapter.upsertMany(state, action.payload);
    },
    [fetchCountries.rejected]: (state) => {
      state.status = 'failed';
    },
  },
});

export const { selectAll: selectAllCountries } = countriesAdapter.getSelectors(
  (state) => state.countries
);

export const selectCountriesStatus = (state) => state.countries.status;

export default slice.reducer;
