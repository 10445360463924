/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';
import { fetchBoards, createBoard, updateBoard, removeBoard } from './action';

export const boardSlice = createSlice({
  name: 'board',
  initialState: {
    boards: [],
    isLoading: true,
    error: null,
  },
  reducers: {},
  extraReducers: {
    [fetchBoards.pending]: (state) => {
      state.isLoading = true;
      state.error = null;
    },
    [fetchBoards.fulfilled]: (state, { payload }) => {
      state.boards = payload;
      state.isLoading = false;
    },
    [fetchBoards.rejected]: (state) => {
      state.isLoading = false;
      state.error = true;
    },
    [createBoard.fulfilled]: (state, { payload }) => {
      state.boards.push(payload);
    },
    [updateBoard.fulfilled]: (state, { payload }) => {
      const findBoard = state.boards.find((board) => board._id === payload._id);
      Object.assign(findBoard, payload);
    },
    [removeBoard.fulfilled]: (state, { payload }) => {
      const newBoards = state.boards.filter((board) => board._id !== payload);
      state.boards = newBoards;
    },
  },
});

export default boardSlice.reducer;
