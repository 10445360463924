/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';
import {
  login,
  me,
  loginWithFacebook,
  register,
  updateCurrentUser,
  forgotPassword,
  resetPassword,
  updateProfile,
  updatePassword,
  registerGoogle,
  registerFacebook,
  loginFacebook,
  loginGoogle,
  changeUserWelcome,
  deleteProfileImage,
  uploadFile,
  accountDelete,
  registerApple,
  loginApple,
} from './action';
import { uploadRequest } from './apis';

const token = localStorage.getItem('token');
const lang = localStorage.getItem('lang');
export const AuthSlice = createSlice({
  name: 'auth',
  initialState: {
    token: token || null,
    currentUser: null,
    isLoading: false,
    error: null,
    lang: lang || 'EN',
  },
  reducers: {
    setProfile(state, { payload }) {
      Object.assign(state.currentUser, payload);
    },
    logout(state) {
      localStorage.removeItem('token');
      state.token = null;
      state.currentUser = null;
      state.error = null;
    },
    setLang(state, { payload }) {
      state.lang = payload;
      localStorage.setItem('lang', payload);
    },
    clearError(state) {
      state.error = null;
    },
  },
  extraReducers: {
    [register.pending]: (state) => {
      state.isLoading = true;
      state.error = null;
    },
    [register.fulfilled]: (state, { payload }) => {
      state.token = payload;
    },
    [register.rejected]: (state, { payload }) => {
      state.isLoading = false;
      state.error = payload;
      state.token = null;
    },
    [registerGoogle.pending]: (state) => {
      state.isLoading = true;
      state.error = null;
    },
    [registerGoogle.fulfilled]: (state, { payload }) => {
      state.token = payload;
    },
    [registerGoogle.rejected]: (state, { payload }) => {
      state.isLoading = false;
      state.error = payload;
      state.token = null;
    },
    [registerApple.pending]: (state) => {
      state.isLoading = true;
      state.error = null;
    },
    [registerApple.fulfilled]: (state, { payload }) => {
      state.token = payload;
    },
    [registerApple.rejected]: (state, { payload }) => {
      state.isLoading = false;
      state.error = payload;
      state.token = null;
    },
    [registerFacebook.pending]: (state) => {
      state.isLoading = true;
      state.error = null;
    },
    [registerFacebook.fulfilled]: (state, { payload }) => {
      state.token = payload;
    },
    [registerFacebook.rejected]: (state, { payload }) => {
      state.isLoading = false;
      state.error = payload;
      state.token = null;
    },
    [login.pending]: (state) => {
      state.isLoading = true;
      state.error = null;
    },
    [login.fulfilled]: (state, { payload }) => {
      state.token = payload;
    },
    [login.rejected]: (state, { payload }) => {
      state.isLoading = false;
      state.error = payload;
      state.token = null;
    },
    [loginFacebook.pending]: (state) => {
      state.isLoading = true;
      state.error = null;
    },
    [loginFacebook.fulfilled]: (state, { payload }) => {
      state.token = payload;
    },
    [loginFacebook.rejected]: (state, { payload }) => {
      state.isLoading = false;
      state.error = payload;
      state.token = null;
    },
    [loginGoogle.pending]: (state) => {
      state.isLoading = true;
      state.error = null;
    },
    [loginGoogle.fulfilled]: (state, { payload }) => {
      state.token = payload;
    },
    [loginGoogle.rejected]: (state, { payload }) => {
      state.isLoading = false;
      state.error = payload;
      state.token = null;
    },
    [loginApple.pending]: (state) => {
      state.isLoading = true;
      state.error = null;
    },
    [loginApple.fulfilled]: (state, { payload }) => {
      state.token = payload;
    },
    [loginApple.rejected]: (state, { payload }) => {
      state.isLoading = false;
      state.error = payload;
      state.token = null;
    },
    [me.pending]: (state) => {
      state.isLoading = true;
      state.error = null;
    },
    [me.fulfilled]: (state, { payload }) => {
      state.isLoading = false;
      state.currentUser = payload;
    },
    [me.rejected]: (state, { payload }) => {
      state.isLoading = false;
      state.error = payload;
      state.currentUser = null;
      state.token = null;
    },
    [updateCurrentUser.fulfilled]: (state, { payload }) => {
      state.isLoading = false;
      state.error = null;
      state.currentUser = { ...state.currentUser, ...payload };
    },
    [updateProfile.fulfilled]: (state, { payload }) => {
      state.currentUser = { ...state.currentUser, ...payload };
    },
    [changeUserWelcome.fulfilled]: (state) => {
      state.currentUser = { ...state.currentUser, isWelcome: true };
    },
    [uploadRequest.fulfilled]: (state, { payload }) => {
      state.currentUser = { ...state.currentUser, payload };
    },
    [updatePassword.fulfilled]: (state) => {
      state.currentUser = { ...state.currentUser };
    },
    [deleteProfileImage.pending]: (state) => {
      state.error = null;
    },
    [deleteProfileImage.fulfilled]: (state, { payload }) => {
      state.currentUser = { ...state.currentUser, ...payload };
    },
    [deleteProfileImage.rejected]: (state, { payload }) => {
      state.error = payload;
    },
    [uploadFile.pending]: (state) => {
      state.error = null;
    },
    [uploadFile.fulfilled]: (state, { payload }) => {
      state.currentUser = { ...state.currentUser, avatar: payload };
    },
    [uploadFile.rejected]: (state, { payload }) => {
      state.error = payload;
    },
    [loginWithFacebook.fulfilled]: () => {},
    [forgotPassword.pending]: (state) => {
      state.error = null;
    },
    [forgotPassword.rejected]: (state, { payload }) => {
      state.error = payload;
    },
    [accountDelete.fulfilled]: () => {},
    [accountDelete.pending]: (state) => {
      state.error = null;
    },
    [accountDelete.rejected]: (state, { payload }) => {
      state.error = payload;
    },
    [resetPassword.pending]: (state) => {
      state.error = null;
    },
    [resetPassword.rejected]: (state, { payload }) => {
      state.error = payload;
    },
  },
});

export const selectFullName = ({ auth }) =>
  auth.currentUser
    ? `${auth.currentUser.first_name} ${auth.currentUser.last_name}`
    : '';

export const selectCurrentUser = ({ auth }) => auth.currentUser;

export const { logout, clearError, setLang, setProfile } = AuthSlice.actions;

export default AuthSlice.reducer;
