/* eslint-disable no-param-reassign */
import { createEntityAdapter, createSlice } from '@reduxjs/toolkit';
import { fetchAllPurchase } from './action';

export const purchaseAdapter = createEntityAdapter({
  selectId: (film) => film._id,
});

const initialState = purchaseAdapter.getInitialState({
  status: 'idle', // 'idle' | 'loading' | 'succeeded' | 'failed'
});
export const purchaseSlice = createSlice({
  name: 'purchase',
  initialState,
  reducers: {
    setLoadingStatus: (state) => {
      state.status = true;
    },
  },
  extraReducers: {
    [fetchAllPurchase.pending]: (state) => {
      state.status = 'loading';
    },
    [fetchAllPurchase.fulfilled]: (state, { payload }) => {
      purchaseAdapter.setAll(state, payload);
      state.status = 'succeeded';
    },
    [fetchAllPurchase.rejected]: (state) => {
      state.status = 'failed';
    },
  },
});

export const { clearError, setLoadingStatus } = purchaseSlice.actions;

export const { selectAll: selectAllPurchase } = purchaseAdapter.getSelectors(
  (state) => state.purchase
);

export const selectPurchaseStatus = (state) => state.purchase.status;

export default purchaseSlice.reducer;
